<template>
  <div>
    <div style="padding: 8px">
      <v-btn v-on="on" color="primary" @click="acaoModal(true)">{{ $t('label.gerar_aap') }}</v-btn>
    </div>
    <v-dialog v-model="showModal" max-width="800">
      <v-card>
        <v-card-title>
        <span class="bar">
          {{ $tc('title.confirma_gerar_aap') }}
        </span>
        </v-card-title>
        <br>
        <v-card-subtitle>
          {{ $tc('title.gerar_lista_aap') }}
        </v-card-subtitle>
        <v-card-text>
          <v-data-table
            :headers="cabecalho"
            :items="lista">
            <template v-slot:item.cliente_recebedor="{ item }">
              {{ item.clienteRecebedor }}
            </template>
            <template v-slot:item.total_pagamentos_aap="{ item }">
              {{ getMoney(item.totalPagamentosAap) }}
            </template>
            <template v-slot:item.lista_cod_pagamentos="{ item }">
              {{ item.listaCodPagamentos.join([',']) }}
            </template>
            <template slot="no-data" v-if="houveSelecao">
              <v-alert :value="true">
                {{ $tc('label.aaps_ja_geradas') }}
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click.native="acaoModal(false)">{{ $t('label.nao') }}</v-btn>
          <v-btn color="primary" v-if="lista.length>0" @click="gerarAap">{{ $t('label.sim') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { listarPagamentosPendentesSuzano } from '@/common/resources/pagamento-suzano';
import { getMoney } from '@/produto/common/functions/helpers';
import basePath from '@/produto/common/functions/api-resource';
import exportacao from '../../common/downloads';

export default {
  name: 'GeracaoAapArquivo',
  components: {
  },
  props: {
    pagamentos: Array,
    houveSelecao: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pagamentoResources: this.$api.pagamentoSuzano(this.$resource),
      showModal: false,
      cabecalho: [
        {
          text: this.$tc('label.cliente_recebedor', 1),
          value: 'cliente_recebedor',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$tc('label.total_pagamentos_aap', 1),
          value: 'total_pagamentos_aap',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$tc('label.lista_cod_pagamentos', 1),
          value: 'lista_cod_pagamentos',
          sortable: true,
          align: 'center',
        },
      ],
      pagination: {
        itemsPerPage: 10,
      },
      totalPage: 0,
      lista: [],
      filtrosAplicados: {},
    };
  },
  methods: {
    getMoney,
    gerarAap() {
      const getBasePath = basePath('job', 'suzano/pagamento/listagem');
      const servico = 'gerar-aap';
      const resource = exportacao(this.$http);
      const parametros = this.lista;
      this.acaoModal(false);
      resource.download(getBasePath, parametros, servico)
        .then(() => {
          this.$toast(this.$t('message.aap_gerada_sucesso'));
          this.$emit('GeracaoAaapArquivo_sucesso');
          this.$forceUpdate();
        })
        .catch(() => {
          this.$toast(this.$t('message.aap_gerada_erro'));
        });
    },
    acaoModal(boolean) {
      if (boolean) {
        this.buscar();
      }
      this.showModal = boolean;
    },
    buscar() {
      const idsPagamentosFiltro = [];
      this.pagamentos.forEach((pagamento) => {
        idsPagamentosFiltro.push(pagamento.id);
      });

      this.filtrosAplicados.ids_pagamentos = idsPagamentosFiltro;

      this.consultar(listarPagamentosPendentesSuzano, (response) => {
        this.lista = response.data.resposta;

        this.totalPage = response.data.quantidadeRegistrosPagina;
        setTimeout(this.finalizandoBusca);
      });
    },
    consultar(consulta, sucesso) {
      if (this.buscando) return;
      this.buscando = true;

      const params = {
        ...this.filtrosAplicados,
      };

      consulta(params, this.$resource)
        .then(sucesso)
        .catch((err) => {
          this.finalizandoBusca();
          this.$error(this, err);
        });
    },
    finalizandoBusca() {
      this.buscando = false;
    },
  },
};
</script>
